@import "../constant";

.map-arena-modal-title {
  color: $darkBlue;
}

.map-arena-modal-section {
  color: $darkBlue;
  border-bottom: 2px solid $darkBlue;
  margin-bottom: 1rem;

  p {
    margin: 0;
  }

  .map-arena-modal-opening {
    margin-bottom: 0.5rem;
  }
}

.map-overlay-opening-open {
  color: green;
  padding-right: 1rem;
}

.map-overlay-opening-closed {
  color: #DC2036;
  padding-right: 1rem;
}
.gameMessageModalClose {
  border-radius: 15px;
  margin-top: 5%;
  border: none;
  width: 45%;
  justify-content: center;

  &.action {
    background-color: #31698D; // change color here
  }

  &.retour {
    background-color: $red;
  }
}

.gameMessageModalActionDiv {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
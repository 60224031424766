.profil{
  width: 100%;
  height: 100%;
  background-color: #09273F;
}

.profil-up{
  position: fixed;
  width: 100%;
  height: 40vh;
  background-color: #174061;
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow: hidden;
  clip-path: polygon(0% 0%, 0% 100%, 100% 80%, 100% 0%);
}

.profil-avatar{
  padding-left: 5%;
  width: 80%;
  overflow: hidden;
  height: 100%;
  padding-top: 5%;
}

.profil-info {
  font-size: 32px;
  font-family: 'Mouse Memoirs', sans-serif;
  text-align: left;
  padding-right: 10%;
}

.modify-button{
  background-color: #31698D;
  font-size: 0.7rem;
  text-align: left;
  width: auto;  
  margin-bottom: 0;
  font-weight: lighter;
  // font-size: small;
}

.profil-pseudo{
  display: flex;  
  flex-direction: row;
}

.profil-elo{
  display: flex;  
  flex-direction: row;
  font-weight: lighter;
  font-size: small;
}

.profil-stat {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 1vh;
}

.profil-ranking {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2vh;
}

.profil-presentation {
  margin-top: 40vh;
  width: 100%;
}

.gameHistoProfil{
  font-style: italic;
  font-size: 1.5vh;
  font-weight: lighter;  
  letter-spacing: 1px;
}
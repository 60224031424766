#countdown {
  position: fixed;
  text-align: center;
  left: 39vw;
  bottom: calc(40vh - 4vw);
  width: 24vw;
  height: 24vw;
  z-index: 4;

  svg {
    position: absolute;
    right: 0;
    width: 40px;
    height: 40px;   
    transform: rotateY(-180deg) rotateZ(-90deg);

    circle {
      stroke-dasharray: 315px;
      stroke-dashoffset: 0;
      stroke-linecap: round;
      stroke-width: 2px;
      stroke: #09273F;
      fill: none; 
      z-index: 4;
    }
  }
}

#countdown-number {
  color: white;
  display: inline-block;
  line-height: 40px;
}

@keyframes countdown {
  from {
    stroke-dashoffset: 0px;
  }
  to {
    stroke-dashoffset: 315px;
  }
}
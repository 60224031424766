@import "constant";

@import "font";

@import "score";
@import "footer";
@import "menu";
@import "game";
@import "all";
@import "avatar";
@import "home";
@import "profil";
@import "register";

@import "cards/statistic_cards";
@import "cards/arena_ranking_cards";

@import "map/map";

@import "modals/ranking_modal";
@import "modals/qr_scanner_modal";
@import "modals/game_message_modal";
@import "modals/map_arena_modal";

@import "timer/countdown";

html {
  height: 100%;
  font-family: 'Luckiest Guy', cursive;
}

body {
  margin: 0;
  font-family: 'Luckiest Guy', 'Barlow Semi Condensed', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}

button {
  //font-size: medium;
  width: 80vw;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

.map-overlay {
  background-color: white;
  color: $darkBlue;
  width: 80%;
  border-radius: 15px;
  font-size: 12pt;
}

.map-overlay-content {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.arena-name-map{
  margin-top: -12%;
  font-size: 25px;
  color : $darkBlue2;
  font-weight: normal;
  // font-family: 'Luckiest Guy', cursive;
}

.map-overlay-address {
  padding-left: 1rem;
  text-align: left;

  p {
    margin-top: 0;
    margin-bottom: 0.5rem;
  }
}

.map-overlay-action {
  display: flex;
  justify-content: space-around;
  width: 100%;

  .map-overlay-button-more {
    height: 3rem;
    margin-right: 1rem;
    // background-color: #477B9C;
  }
  .map-overlay-button-none {
    // background-color: #477B9C;
  }
}

.map {
  width: 100%;
  height: 100%;
  position: relative;
  justify-content: center;
  display: flex;
}

.map-recommand-button{
  background-color: #477B9C; //#174061;
  display: flex;
  align-items: center;
  position: absolute;
  justify-content: center;
  font-size: 20px;
  bottom: 12vh;
  height: 6vh;
  width: 66%;
  margin-top: -5vh;
  padding: 1vh 1vh;
  z-index: 3;
}

.link-map{
  color: white;
}
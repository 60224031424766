.home{
  box-sizing: border-box;
  width: 60%;
  /* gap: 15px; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 370px;
  height: 40%;
}

.avatar{
  width: 90%;
  max-width: 400px;
  clip-path: polygon(0% 0%, 0% 100%, 100% 80%, 100% 0%);
  // clip-path: polygon(0% 0%, 0% 93%, 500% -90%);
  overflow: hidden;
  // height: 40%;
  // max-height: 330px;
  // margin-bottom: -50px;
  /* margin-top: 10px; */
  /* clip-path: inset(50px 50px 50px 0 round 10px); */
}

.elo{
  display: flex;
}

.btns{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.button-avatar:active{
  box-shadow: none;
}

.btns > .button-avatar:hover{
  background-color: #ffffff;
}

.avatar-index{
  z-index: -2;
  width: 50vw;
}

.avatar-sprite{
  position: relative;
  height: 41vh;
}

// #gen{
//   /* background-color: #3D82BC;
//   width: 100%;
//   font-size: 32px;
//   height: 100%; */
//   /* text-align: right; */
//   /* background: #FFFFFF 0% 0% no-repeat padding-box; */
//   /* margin-left: 200px; */
//   /* border-radius: 50px; */
//   width: 48px;
//   height: 48px;
//   opacity: 1;
//   // position: absolute;
//   right: 60px;
//   top: 400px;
// }

.button-arrow{
  padding: 0;
  border: none;
  box-shadow: none;
  background: none;
  font-size: 10vh;
  width: 40px;
  opacity: 1;
  position: absolute;
  height: 10vh;
  cursor: pointer;
}

#gen-up{
  right: 60px;
}

#gen-down{
  // background-color: #EA6A65;
  left: 60px;
}

// @media (prefers-reduced-motion: no-preference) {
//   .avatar {
//     // animation: avatar 0.8s normal;
//     -webkit-transition: opacity .5s linear 0.2s;
//        -moz-transition: opacity .5s linear 0.2s;
//                  transition: opacity .5s linear 0.2s;
// /* we set the opacity to 1 to do the magic */
//    opacity: 1;
//   }
// }

// @keyframes gen-up {
//   from {
//     transform: rotate(360deg);
//   }
//   to {
//     transform: rotate(0deg);
//   }
// }

// #down{
//   background-color: #EA6A65;
//   color: black;
// }
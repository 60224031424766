.ranking-modal-close {
  position: fixed;
  right: 5vw;
  top: 5vw;
  width: 5vw;
  height: 5vw;
  border-radius: 100%;
  z-index: 1;
  border: none;
}

.ranking-modal-title {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  color: white;
  height: 12vh;
  padding-top: 3vh;
}

.ranking-modal-arena-name {
  font-size: 2rem;
  z-index: 3;
}

.ranking-arena{
  width: 100%;
  color: white;  
}

.podium{
  height: 25vh;    
  display: flex;
}

.arena-ranking-cards{
  position: fixed;
  justify-content: center;
  top: 42vh;
  left: 0;
  width: 100%;
  height: 58vh;
  overflow: auto;
  z-index: 2;
}
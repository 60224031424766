.statistic-card {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.statistic-card-stat {
  font-weight: bold;
}

.statistic-card-label {
  font-weight: lighter;
  font-size: small;
}
.home-link {
  align-items: center;
  display: flex;
  height: 100%;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  width: 100%;
  // padding-top: calc(30vmin + 1rem);
}

.home-gape .home-gape-20 .home-gape-30 .home-gape-60 .home-gape-40 {
  align-items: center;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  width: 100%;
}

.home-gape {
  height: 100%;
}

.home-gape-60 {
  height: 140%;
}

.home-gape-40 {
  height: 50%;
}

.home-gape-30 {
  height: 60%;
}

.home-gape-20 {
  height: 20%;
}

.link-home {
  color: $lightGray;
  padding-top: 8vh;
  font-size: medium;  
  letter-spacing: 0.9px;
  font-weight: normal;
}
@import url('https://fonts.googleapis.com/css2?family=Luckiest+Guy&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Mouse+Memoirs&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Barlow+Semi+Condensed:wght@400;500;700&display=swap');

// font-family: 'Barlow Semi Condensed', sans-serif;
// font-family: 'Luckiest Guy', cursive;


// Pour les titres, Luckiest Guy
// https://fonts.google.com/specimen/Luckiest+Guy?query=luckiest

// Pour tout le reste, celle de la maquette n’est pas une Google font, donc j’ai trouvé une bonne alternative : Barlow semi condensed 
// https://fonts.google.com/specimen/Barlow+Semi+Condensed
// Qui sera utilisé en:
// - regular (pour les petites infos, niveaux etc)
// - medium (pour les instructions, et autres infos importantes)
// - bold (pour les pseudos)

@import "constant";

.BabyfootArenas-game {
  background-position: center top;
  background-repeat: no-repeat;
  background-color: #174061;
  /* margin-top: 70px; */
  height: 100%;
}

$top-red: 60vh;

.equipe-top {
  position: absolute;
  top: 15vh;
  left: 0;
  width: 100vw;
  height: 45vh;
  // opacity: 0.6; // TO DO : à ajouter lors de la saisie du score puis à enlever
}

.equipe-bottom {
  position: absolute;
  height: 45vh;
  width: 100vw;
  bottom: 2vh;
  left: 0;
  overflow: hidden;
  clip-path: polygon(0% 20%, 0% 100%, 100% 100%, 100% 0%);
    // opacity: 0.6; // TO DO : à ajouter lors de la saisie du score puis à enlever
}

.equipe-bleu {
  background-color: $darkBlue;
}

.equipe-rouge {
  background-color: $red;
}

//same as equipe-rouge > diff bottom = épaisseur du bandeau
.bandeau-blanc {
  position: absolute;
  background: #FFFFFF;
  height: 45vh;
  width: 100vw;
  bottom: 5vh;
  left: 0;
  overflow: hidden;
  clip-path: polygon(0% 20%, 0% 100%, 100% 100%, 100% 0%);
}
.score {
  position: absolute;
  // left: 2vw;
  height: 15vh;
  width: 90vw;
  z-index: 3;
  // text-align: start;
  left: 2vw;
  
  .picto-minus {
    width: 18vw;
    opacity: 0.90;
  }

  .picto-plus {
    width: 18vw;
    opacity: 0.90;
  }

  .score-number {
    font-size: 35vw;
    width: 40vw;
    display: inline-block;
  }
}

.score-top {
  top: 30vh;
}

.score-bottom {
  bottom: 18vh;
}

.middle-place {
  position: fixed;
  // background: #31698D;
  left:38vw;
  bottom: calc(40vh - 9vw);
  width: 24vw;
  height: 24vw;
  z-index: 3;
  border-radius: 50%;
  color: black;
  font-size: 3.5vw;
  color: white;
  background: #31698D 0% 0% no-repeat padding-box;
  border-color: white;
  box-shadow: 0px 4px 7px #09273F54;
  border: 4px solid white;
  opacity: 1;
}

.message {
  position: fixed;
  bottom: calc(40vh - 5vw);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.confirmer-oui {
  position: absolute;
  bottom: -7vw;
  left: -4vw;

  width: 10vw;
  height: 10vw;
  border-radius: 50%;

  background: white;
  color: black;
  font-size: 3vw;
}

.confirmer-non {
  position: absolute;
  bottom: -7vw;
  right: -4vw;

  width: 10vw;
  height: 10vw;
  border-radius: 50%;

  background: white;
  color: black;
  font-size: 3vw;
}

.chapeau-rouge {
  position: absolute;
  left: 0;
  bottom: calc(100vh - $top-red);
  //height : 0;
  //width : 0;
  border-bottom : calc((50vh - $top-red) * 2) solid $red;
  border-left : 100vw solid transparent;
  z-index: 2;
}

.player {
  width: 40vw;
  height: 40vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.player-avatar {
  overflow: hidden;
}

// .player-avatar-highlight{
//   background-color: #C2C5D1;
//   clip-path: polygon(10% 0, 90% 0%, 100% 100%, 0% 100%);
//   opacity: 90%;
// }

.player-name-welcome{  
  font-family: 'Mouse Memoirs', sans-serif;
}

.playerInfo{
  position: absolute;
  bottom: 10vh;
  width: 100%;
  font-family: "Mouse Memoirs", sans-serif;
  font-weight: normal;
  font-size: 3vh;
  letter-spacing: 1px;
}

.player-capitaine{
  font-weight: lighter;
  font-style: italic;
  font-size: 2vh;
  letter-spacing: 1px;
}

.left {
  position: absolute;
  bottom: 0;
  left: 10vw;
}

.right {
  position: absolute;
  bottom: 0;
  right: 10vw;
}

.picto-remove {
  position: absolute;
  width: 3vw;
  height: 3vh;
  top: 6vh;
  right: 0;
  background: transparent;
  border: none;
  padding: 0;
  box-shadow: none;
}

.picto-remove-img{
  width: 2vh;
}

.arena{
  margin-top: 4vh;
  font-size: 15px;
  font-weight: lighter;
  font-family: 'Barlow Semi Condensed', "Gill Sans Extrabold", cursive;
  letter-spacing: 2px;
}

.arena-name{
  font-size: 30px;
  font-weight: normal;
  font-family: 'Luckiest Guy', cursive;
}
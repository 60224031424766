.BabyfootArenas {
  text-align: center;
  font-family: 'Barlow Semi Condensed', "Gill Sans Extrabold", cursive; /* 'Roboto Mono', monospace; Helvetica */
  font-weight: bold;
  color: #ffffff;
  background-color: #174061; /* color fond 1 */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  height: 100vh;
}

.BabyfootArenas-body {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: 'Luckiest Guy', cursive;
  /* background-color: $darkBlue; */
  /* background: transparent linear-gradient(180deg, #DC203600 0%, #DC2036 100%) 0% 0% no-repeat padding-box; */
}

.BabyfootArenas-header {
  position: absolute;
  left: 0;
  background-color: #09273F;
  top: 0;
  right: 0;
  clip-path: polygon(0% 0%, 0% 100%, 500% 0%);
}

.BabyfootArenas-header-50 {
  padding-top: 10vh;
  padding-bottom: 20vh;
}

.BabyfootArenas-header-40 {
  padding-top: 10vh;
  padding-bottom: 10vh;
}

.BabyfootArenas-header-30 {
  padding-top: 10vh;
  padding-bottom: 15vh;
}

.BabyfootArenas-header-20 {
  padding-top: 5vh;
  padding-bottom: 5vh;
}

.BabyfootArenas-header-10 {
  padding-top: 0vh;
  padding-bottom: 5vh;
}

.slogan {
  padding-top: 20vh;
  opacity: 0.8;
}

button, .btn-play {
  font-family: 'Luckiest Guy', cursive;
  font-weight: normal;
  color: #ffffff;
  background: $darkBlue 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 10px;
  opacity: 1;
  font-size: 18pt;
  text-align: center;
  margin-bottom: 15px;
  width: 60%;
  letter-spacing: 0.9px;
  padding: 7px 0 0px 0;
  /* font: normal normal bold 18px/27px Ruddy; */
}

.btn-active{  
  background: $darkBlue3 0% 0% no-repeat padding-box;
}

input {
  font-family: 'Mouse Memoirs', sans-serif;
  font-weight: normal;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 10px;
  font-size: 18pt;
  text-align: left;
  margin-bottom: 15px;
  letter-spacing: 0.9px;
  color: $darkBlue;
  text-indent: 20px;
  height: 44px;
  // width: 70%;
  // opacity: 0.4;
  // font: normal normal bold 18px/27px Helvetica;  
}

.form-content {
  display: grid;
  grid-template-columns: 1fr 1.8fr 0.2fr;
  // grid-template-rows: 1fr 1fr 1fr; 
  gap: 0px 0px;
  grid-template-areas: 
    "label label label"
    "form form button"
    "error error error";
  justify-content: stretch;
}

.label-form {
  grid-area: label;
  text-align: center;
  padding-bottom: 2px;
}

.form-control {
  // width: 90%;
  grid-area: form;
}

.button-showPassword {
  grid-area: button;
  height: 34px;
  width: 34px;
  text-align: center;
  font-family: "Mouse Memoirs", sans-serif;
  font-weight: normal;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1607843137);
  border-radius: 10px;
  font-size: 18pt;
  margin-bottom: 15px;
  margin-left: 10px;
  margin-top: 10px;
  letter-spacing: 0.9px;
  // color: #0d3758;
  // text-indent: 20px;
  // height: 44px;
}

.error-pwd {
  grid-area: error;
}

a:link {
  text-decoration: none;
}

.btn-play:disabled {
  color: #c2c5d175;
}

.BabyfootArenas-logo {
  height: 30vmin;
  // pointer-events: none;
  opacity: 0.8;
}

// @media (prefers-reduced-motion: no-preference) {
.BabyfootArenas-logo {
  // animation: BabyfootArenas-logo-spin 1.5s normal;
  top: 50%;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
}

.cgu {
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  font-size: small;
  font-weight: normal;
  overflow-y: scroll;
  text-align: justify;
  padding: 40px;

}
// }
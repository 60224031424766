.bottom-menu {
  background-color: $darkBlue;

  position: absolute;
  height: 10vh;
  width: 100%;
  bottom: 0.5vh;

  display: flex;
  align-items: center;
  justify-content: center;

  .item {
    margin-top: 0.5vh;
    flex-grow: 0.25;
    flex-basis: 0;    
    display: flex;
    justify-content: center;
  }
}

.btn-menu{
  width: 6vh;
}

.picto{
  width: 5vh;
  margin-top: 5px;
}

.text-menu-navigation{
  font-size: 10px;
  display: flex;
  justify-content: center;
  padding-right: 0;
  padding-left: 0;
}
.footer {
  background-color: $darkBlue;
  font-size: 1.5vh;

  font-family: 'Mouse Memoirs', sans-serif;
  font-weight: lighter;

  position: fixed;
  width: 100%;
  height: 2vh;
  bottom: 0;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 0.1;

  .link {
    color: $lightGray;
  }
}


.register-form {
  display: flex;
  flex-direction: column;
  width: 80vw;
  // padding-top: 30vmin;
}

.player-selection-form{  
  // display: flex;
  // flex-direction: column;
}

.button-submit{
  align-self: center;
}

.register-error {
  color: #EA6A65;
  margin-bottom: 5px;
  // margin-top: 0;
  margin-top: -10px;
}

.input-container {
  display: -ms-flexbox; /* IE10 */
  display: flex;
  width: 100%;
  margin-bottom: 15px;
}

.icon {
  padding: 10px;
  background: dodgerblue;
  color: white;
  min-width: 50px;
  text-align: center;
}

.icon:hover {
  cursor: pointer;
}

.input-field {
  width: 100%;
  padding: 10px;
  outline: none;
}

.input-field:focus {
  border: 2px solid dodgerblue;
}

.input-error {
  border: 2px solid red;
}

.icon-error {
  background: red;
}

.eye{
  width: 34px;
  color: red;
}
.arena-ranking-card {
  width: 45%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-radius: 10px;
  padding: 1vh 2vh;
  background-color: $darkBlue2;
  // ICI border
  box-shadow: 0px 3px 6px #00000029;
}

.arena-ranking-card-rank {
  font-weight: bold;
  padding: 1vh;
  background-color: $darkBlue3;
}

.arena-ranking-card-name {
  font-weight: lighter;
  font-size: medium;
}

/* PLAYER */

.arena-ranking-player-card {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0vh 8vh;
  font-size: 1.8rem;
  font-family: 'Mouse Memoirs', sans-serif;
}

.arena-ranking-player-card-rank {
}

.arena-ranking-player-card-pseudo {
  padding: 1vh;
}

.arena-ranking-player-card-points {
}

.me-highlighted{
  background-color: #477B9C;
  opacity: 80%;
}

#flex { 
  display: flex; 
}

// #a { order: 2; }
#b { order: 1; }
#c { order: 3; }

.arena-ranking-avatar{
  height: 25vh;
  width: 100%;
}

.arena-ranking-avatar-card-2{
  height: 25vh;
  width: 33vw;
  overflow: hidden;
  z-index: 2;
}

.arena-ranking-avatar-card-1{
  height: 25vh;
  width: 38vw;
  overflow: hidden;
  z-index: 3;
}

.arena-ranking-avatar-card-3{
  height: 25vh;
  width: 30vw;
  overflow: hidden;
  z-index: 1;
}

.positionOnTShirt{
  margin: 0 auto;
  text-align: center;
  // z-index: 3;
  // position: relative;
  // top: 20vh;
  // left: 15vw;
}